import React, { forwardRef, Text } from 'react';
import Button from '@material-ui/core/Button';
import { withRouter } from 'react-router-dom';

import MaterialTable from '@material-table/core';
import axios from 'axios';
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import IconButton from '@material-ui/core/IconButton';
import CheckBoxIcon from '@material-ui/icons/CheckBox';


const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
  };


class Ordinare extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            products: [],
            file: null
        }
    }
    base64 = file => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(this.state.file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => console.log(reader.error);
    });

    downloadFiles = (file_url, supplier) => {

        //console.log(supplier)
        window.open(file_url);
        if(supplier === 'Garofoli'){
            window.open('https://back.rauccihomedesign.it/modulo_ordine_garofoli.pdf');
        }

    }
    
    retrieveProducts = async() => {
        const res = await axios.get('https://back.rauccihomedesign.it/api/v1/estimates/' + this.props.location.state.id, {
            headers: { Authorization: 'Bearer ' + sessionStorage.getItem('token')}
        })
        
        const toBeShippedProducts = res.data.products.filter(x => !x.shipped).filter(x => x.supplier);
        const toBeShippedHandles = res.data.handles.filter(x => !x.shipped);

        const array = [...toBeShippedHandles, ...toBeShippedProducts];
        
        this.setState({ products: array });
    }
    componentDidMount = async() => {
        await this.retrieveProducts();
    }

    shipAll = async (data) => {
        let res = await axios.post('https://back.rauccihomedesign.it/api/v1/file-upload', {estimate_id: data.estimate_id, product_id: data.product }, {
            headers: { Authorization: 'Bearer ' + sessionStorage.getItem('token')}});
        window.open(res.data.message);

        if(Object.keys(data).includes('product')){
            await this.state.products.filter(x => x.supplier === data.supplier).forEach(async (x) => {
                const res = await axios.post('https://back.rauccihomedesign.it/api/v1/order', {
                    estimate_id: data.estimate_id,
                    product: x.product
                },
                {
                    headers: { Authorization: 'Bearer ' + sessionStorage.getItem('token')}
                });
                if(res.status === 200){
                    await this.retrieveProducts();
                }
            })
           
        }else{
            const res = await axios.post('https://back.rauccihomedesign.it/api/v1/order', {
                id: data.row_id
            },
            {
                headers: { Authorization: 'Bearer ' + sessionStorage.getItem('token')}
            });

            if(res.status === 200){
                await this.retrieveProducts();
            }
        }
    }
    render(){
        return (
            <div>
               
                <h1>Contratti</h1>
          
                <MaterialTable title="Elenco prodotti da ordinare"
                options={{
                    paging:true,
                    pageSize:50,       // make initial page size
                    emptyRowsWhenPaging: false,   // To avoid of having empty rows
                    pageSizeOptions:[50,100,150,200],    // rows selection options
                  }}
                icons={tableIcons} data={this.state.products} columns={[
                    {
                        title: 'Nome prodotto',
                        field: 'name',
                        render: data => <p>{data.name}</p>
                    },
                    {
                        title: 'Fornitore',
                        field: 'supplier_name',
                    },
                    {
                        title: 'Quantità',
                        field: 'quantity',
                    },
                    {
                        title: 'Azioni',
                        field: 'updated_at',
                        render: data => 
                            <div>
                                <IconButton title="Ordina" color="primary" onClick={() => this.shipAll(data)}>
                                    <CheckBoxIcon></CheckBoxIcon>
                                </IconButton>
                                {/* <IconButton title="Accedi al tracciato" color="primary" onClick={() => this.uploadFile(data)}>
                                    <SystemUpdateAltIcon></SystemUpdateAltIcon>
                                </IconButton> */}
                                
                                
                            </div>
                            
                        
                    }
                ]}></MaterialTable>
            </div>
        )
    }
}

export default withRouter(Ordinare);