import { Grid, TextField, FormControl, Select, MenuItem, InputLabel, Button } from '@material-ui/core';
import React from 'react';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import MuiAlert from '@material-ui/lab/Alert';
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import itLocale from 'date-fns/locale/it';

class Spesa extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            price: null,
            causale: null,
            date: new Date()
        }
    }

    addSupplier = async () => {
      
        let responseWallet = await axios.post('https://back.rauccihomedesign.it/api/v1/contabilities', {
                causale: this.state.causale,
                isExpense: true,
                price: this.state.price,
                password: this.props.location.password,
                name: this.props.location.name,
                date: this.state.date
            },
            {
                headers: { Authorization: 'Bearer ' + sessionStorage.getItem('token')}
            }
        );
        console.log(responseWallet);
        
        this.props.history.push({pathname: '/portafoglio', password: this.props.location.password, name: this.props.location.name});


    }

    componentDidMount = async() => {
        

    }
    render(){
        return (
            <div>
                <h1>Inserisci nuovo movimento</h1>
                <Grid container spacing={3}>

                    <Grid item xs={6}>
                        <h2>Dati Spesa</h2>
                        <FormControl fullWidth>
                            <TextField type="text" label="Causale" value={this.state.causale} onInput={(e) => this.setState({causale: e.target.value})}></TextField> 
                        </FormControl>
                        <FormControl fullWidth>
                            <TextField type="number" label="Valore spesa" value={this.state.price} onInput={(e) => this.setState({price: e.target.value})}></TextField> 
                        </FormControl>
                        <FormControl fullwidth>
                        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={itLocale}>
                            <DatePicker
                                label="Data spesa"
                                value={this.state.date}
                                onChange={(e) => this.setState({date: e})}
                                animateYearScrolling
                                fullWidth
                            />
                        </MuiPickersUtilsProvider>
                      
                        </FormControl>
                    </Grid>


                    
                </Grid>
                <Button variant="contained" color="primary" onClick={this.addSupplier} style={{marginTop: 32}}>
                        Aggiungi Movimento
                </Button>

               
            </div>
        )
    }
}

export default withRouter(Spesa);