import React, { forwardRef, Text } from 'react';
import Button from '@material-ui/core/Button';
import { withRouter } from 'react-router-dom';
import { FormControl, TextField } from '@material-ui/core';

import MaterialTable from '@material-table/core';
import axios from 'axios';
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import DateFnsUtils from '@date-io/date-fns';
import itLocale from 'date-fns/locale/it';

import IconButton from '@material-ui/core/IconButton';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import moment from 'moment';

const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
  };


class Ordinare extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            products: []
        }
        this.timeout = 0;
    }
    
    retrieveProducts = async() => {
        const res = await axios.get('https://back.rauccihomedesign.it/api/v1/estimates/' + this.props.location.state.id, {
            headers: { Authorization: 'Bearer ' + sessionStorage.getItem('token')}
        })
        
        const toBeShippedProducts = res.data.products.filter(x => x.shipped && !x.inExit).map(x => ({...x, 
                                    date_arrival: moment(x.date_arrival).isValid() ? moment(x.date_arrival).format('YYYY-MM-DD') : null, 
                                    expected_discharge: moment(x.expected_discharge).isValid() ? moment(x.expected_discharge).format('YYYY-MM-DD') : null
                                }));
        const toBeShippedHandles = res.data.handles.filter(x => x.shipped);
        const array = [...toBeShippedHandles, ...toBeShippedProducts];
        console.log(toBeShippedProducts);
        this.setState({ products: [...toBeShippedProducts] });
    }
    componentDidMount = async() => {
        await this.retrieveProducts();
    }
    setShippedValue = async (value, index, id) => {
        const _back = this.state.products;
        _back[index].inExit = value;
        _back[index].arrivalDate = moment().format('YYYY-MM-DD H:m:s');
        await this.setState({products: _back});

        const res = await axios.put('https://back.rauccihomedesign.it/api/v1/products/' + id, {
            inExit: value,
            arrivalDate: moment().format('YYYY-MM-DD H:m:s')

        }, {
            headers: { Authorization: 'Bearer ' + sessionStorage.getItem('token')}
        });
    }
  
  
    changeRowValue = async (id, data, index, date = false) => {
       
        const products = this.state.products;
        const product = products.filter(x => x.product === id)[0];
        product[index] = data;
        console.log(products);
        this.setState({
            products: products
        });
        const val = data;
        clearTimeout(this.timer);
        this.timer = setTimeout(async () => {
         if (val) {
            const res = await axios.put('https://back.rauccihomedesign.it/api/v1/products/' + id, {
                [index]: date ? moment(data).format('YYYY-MM-DD') : data,
                estimate_id: this.props.location.state.id
            }, {headers: { Authorization: 'Bearer ' + sessionStorage.getItem('token')}});
         }
        }, 500);
    }

    getRowValue = async(id, index) => {
        return this.state.products.filter(x => x.product === id)[0][index];
    }
    render(){
        return (
            <div>
               
                <h1>Contratti</h1>

                <MaterialTable title="Elenco prodotti in uscita" icons={tableIcons} data={this.state.products} columns={[
                    {
                        title: 'Nome prodotto',
                        field: 'name',
                        render: data => <p>{data.name}</p>
                    },
                    {
                        title: 'Fornitore',
                        field: 'supplier_name',
                    },
                    {
                        title: 'Quantità',
                        field: 'quantity',
                    },
                    {
                        title: 'Riferimento',
                        field: 'riferimento',
                        render: data => <FormControl fullwidth><TextField label="Riferimento"
                                        onChange={(e) => this.changeRowValue(data.product, e.target.value, 'riferimento', false)} 
                                        value={this.state.products.filter(x => x.product === data.product)[0] ? this.state.products.filter(x => x.product === data.product)[0].riferimento : null}
                                        ></TextField></FormControl>
                    },
                    {
                        title: 'Costo',
                        field: 'goods_cost',
                        render: data => <FormControl fullwidth><TextField label="Costo merce" 
                                        onChange={(e) => this.changeRowValue(data.product, e.target.value, 'goods_cost', false)} 
                                        value={this.state.products.filter(x => x.product === data.product)[0] ? this.state.products.filter(x => x.product === data.product)[0].goods_cost : null}></TextField></FormControl>
                    },
                    {
                        title: 'Data di scarico merce',
                        field: 'data',
                        render: data =>  <FormControl fullwidth>
                                            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={itLocale}>
                                                <DatePicker
                                                    label="Data scarico merce"
                                                    value={this.state.products.filter(x => x.product === data.product)[0] ? this.state.products.filter(x => x.product === data.product)[0].expected_discharge : null}
                                                    onChange={(e) => this.changeRowValue(data.product, e, 'expected_discharge', true)}
                                                    animateYearScrolling
                                                    fullWidth
                                                />
                                            </MuiPickersUtilsProvider>
                                        
                                        </FormControl>
                    },
                    {
                        title: 'Data di consegna',
                        field: 'data',
                        render: data =>  <FormControl fullwidth>
                                            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={itLocale}>
                                                <DatePicker
                                                    label="Data consegna"
                                                    value={this.state.products.filter(x => x.product === data.product)[0] ? this.state.products.filter(x => x.product === data.product)[0].ship_date : null}
                                                    onChange={(e) => this.changeRowValue(data.product, e, 'ship_date', true)}
                                                    animateYearScrolling
                                                    fullWidth
                                                />
                                            </MuiPickersUtilsProvider>
                                        
                                        </FormControl>
                    },
                    {
                        title: 'Note',
                        field: 'shipment_notes',
                        render: data => <FormControl fullwidth><TextField label="Note" 
                                        onChange={(e) => this.changeRowValue(data.product, e.target.value, 'shipment_notes', false)} 
                                        value={this.state.products.filter(x => x.product === data.product)[0] ? this.state.products.filter(x => x.product === data.product)[0].shipment_notes : null}></TextField></FormControl>
                    },
                    // {
                    //     title: 'Azioni',
                    //     field: 'updated_at',
                    //     render: data => 
                    //         <div>
                    //             <IconButton title="Conferma ordine" color="primary" onClick={() => this.shipAll(data)}>
                    //                 <CheckBoxIcon></CheckBoxIcon>
                    //             </IconButton>
                    //         </div>
                    // }
                ]}></MaterialTable>
            </div>
        )
    }
}

export default withRouter(Ordinare);