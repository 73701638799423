import { CardContent, Grid, Typography } from "@material-ui/core";
import { Card } from "@mui/material";
import React, { useEffect, useState } from "react";

const Dashboard = () => {
    const [dashboards, setDashboards] = useState([]);

    const getDashboard = async() => {
        const res = await fetch('https://back.rauccihomedesign.it/api/v1/dashboard', {
            headers: {
                'Authorization': `Bearer ${sessionStorage.getItem('token')}`
            }
        });
       setDashboards([...await res.json()]);
    }
    useEffect(() => {
        getDashboard();
    }, []);
    return (<div>
        {dashboards.sort((a,b) => b.Anno - a.Anno).map(dashboard => (
            <>
            <h2>{dashboard.Anno}</h2>
            <Grid container spacing={4}>
                <Grid item>
                    <Card>
                        <CardContent>
                            <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                IVA Esclusa Ordini
                            </Typography>
                            <Typography variant="h5" component="div">
                                {parseFloat(dashboard['IVA ESCLUSA TOTALE']).toLocaleString('it-IT', {style: 'currency', currency: 'EUR'})}
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item>
                    <Card>
                        <CardContent>
                            <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                Incassi
                            </Typography>
                            <Typography variant="h5" component="div">
                                {parseFloat(dashboard['Incassi']).toLocaleString('it-IT', {style: 'currency', currency: 'EUR'})}
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item>
                    <Card>
                        <CardContent>
                            <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                Preventivi
                            </Typography>
                            <Typography variant="h5" component="div">
                                {dashboard.Preventivi}
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item>
                    <Card>
                        <CardContent>
                            <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                Ordini convertiti
                            </Typography>
                            <Typography variant="h5" component="div">
                                {dashboard.Ordini}
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item>
                    <Card>
                        <CardContent>
                            <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                Tasso conversione
                            </Typography>
                            <Typography variant="h5" component="div">
                                {parseInt((dashboard.Ordini * 100) / dashboard.Preventivi)}% 
                                {/* Preventivi : 100 = Ordini : x */}
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid></>
        ))}
        
        {/* <iframe width="1200" height="1200" src="https://datastudio.google.com/embed/reporting/86022de8-0b88-4d5b-aefa-f12830a5a80f/page/YeaqC" frameborder="0" style={{border:0}} allowfullscreen></iframe> */}
    </div>)
}

export default Dashboard;