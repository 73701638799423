import React, { forwardRef, Text } from 'react';
import { withRouter } from 'react-router-dom';

import MaterialTable from '@material-table/core';
import axios from 'axios';
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import DatePicker, {registerLocale, setDefaultLocale} from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { Grid, TextField, FormControl, Button } from '@material-ui/core';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import moment from 'moment';

import json2xls from 'json2xls';
import { it } from 'date-fns/locale/it';
registerLocale('it', it)

const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
  };


class Portafoglio extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            estimates: [],
            isLoaded: false,
            codice: null,
            contabilities: [],
            backup: [],
            total: 0,
            totalExpenses: 0,
            totalEarnings: 0,
            name: null,
            portafogli: [],
            startDate: new Date(),
            endDate: new Date()
        }

        this.inputRef = React.createRef(null);
    }
    
    componentDidMount = async() => {
        const portafogli = await axios.get('https://back.rauccihomedesign.it/api/v1/contabilities-list', {
            headers: { Authorization: 'Bearer ' + sessionStorage.getItem('token')}
        });

        this.setState({
            portafogli: portafogli.data.map(x => x.name),
            codice: this.props.location.password,
            name: this.props.location.name
        })
        
      if(this.props.location.password){
        const res = await axios.get('https://back.rauccihomedesign.it/api/v1/contabilities/' + this.props.location.name + '?password=' + this.props.location.password, {
            headers: { Authorization: 'Bearer ' + sessionStorage.getItem('token')}
        });
        if(res.data){

            const totalExpenses = res.data.filter(x => x.isExpense);
            const totalEarnings = res.data.filter(x => !x.isExpense);
            this.setState({
                contabilities: res.data.sort((a,b) => (new Date(a.created_at).getTime() > new Date(b.created_at).getTime()) ? 1 : ((new Date(b.created_at).getTime() > new Date(a.created_at).getTime()) ? -1 : 0)),
                backup: res.data.sort((a,b) => (new Date(a.created_at).getTime() > new Date(b.created_at).getTime()) ? 1 : ((new Date(b.created_at).getTime() > new Date(a.created_at).getTime()) ? -1 : 0)),
                isLoaded: true,
                totalExpenses: totalExpenses,
                totalEarnings: totalEarnings,
                total: totalEarnings - totalExpenses
            });
        }
      }
    }

    getWallet = async() => {
        const res = await axios.get('https://back.rauccihomedesign.it/api/v1/contabilities/' + this.state.name + '?password=' + this.state.codice, {
            headers: { Authorization: 'Bearer ' + sessionStorage.getItem('token')}
        });
        if(res.data){
            sessionStorage.setItem('portafoglio', JSON.stringify({
                name: this.state.name,
                password: this.state.codice
            }))
              const totalExpenses = res.data.filter(x => x.isExpense).map(x => x.price).reduce((acc, curr) => acc + curr, 0);
            const totalEarnings = res.data.filter(x => !x.isExpense).map(x => x.price).reduce((acc, curr) => acc + curr, 0);
            
            this.setState({
                contabilities: res.data.sort((a,b) => (new Date(a.created_at).getTime() > new Date(b.created_at).getTime()) ? 1 : ((new Date(b.created_at).getTime() > new Date(a.created_at).getTime()) ? -1 : 0)),
                backup: res.data.sort((a,b) => (new Date(a.created_at).getTime() > new Date(b.created_at).getTime()) ? 1 : ((new Date(b.created_at).getTime() > new Date(a.created_at).getTime()) ? -1 : 0)),
                isLoaded: true,
                totalExpenses: totalExpenses,
                totalEarnings: totalEarnings,
                total: totalEarnings - totalExpenses
            });
        }
    }

    createWallet = async() => {
        const res = await axios.post('https://back.rauccihomedesign.it/api/v1/contability-password', {password: this.state.codice, name: this.state.name}, {
            headers: { Authorization: 'Bearer ' + sessionStorage.getItem('token')}
        });
    }

    printWallet = () => {
        const result = this.state.contabilities.map(x => ({
            'Causale': x.causale,
            'Entrata': !x.isExpense ? (x.price / 100).toLocaleString('it-IT', {style: 'currency', currency: 'EUR'}) : null,
            'Uscita': x.isExpense ? (x.price / 100).toLocaleString('it-IT', {style: 'currency', currency: 'EUR'}) : null,
            'Data': x.date ? moment(x.date).format('DD/MM/YYYY') : moment(x.date).format('DD/MM/YYYY')
        }));

        const decoded = json2xls(result);
        const buf = new ArrayBuffer(decoded.length);
        const view = new Uint8Array(buf);
        for (let i = 0; i !== decoded.length; ++i) {
            view[i] = decoded.charCodeAt(i) & 0xFF;
        }
        const file = new Blob([buf]);
  
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(file);
        link.download = `export-portafoglio.xls`;
        link.click();
    }
    render(){
        return (
            <div>
                
                <h1>Portafoglio</h1>
                <Button variant="contained" color="primary" onClick={() => window.location.reload()} style={{marginTop: 24, marginBottom: 24}}>
                    Ricarica
                </Button>
                {this.state.isLoaded ? ( <div> <Grid container spacing={1}>
                    <Grid item xs={2}><Button color="primary" style={{marginBottom: 32}} onClick={() => this.props.history.push({pathname: '/aggiungi-spesa', password: this.state.codice, name: this.state.name})}>Aggiungi spesa</Button></Grid>
                    {/* <Grid item xs={2}><Button color="primary" style={{marginBottom: 32}} onClick={() => this.props.history.push({pathname: '/aggiungi-entrata', password: this.state.codice, name: this.state.name})}>Aggiungi entrata</Button></Grid> */}
                </Grid>
                <>
                <h4>Totale portafoglio: {(this.state.total / 100).toLocaleString('it-IT', {style: 'currency', currency: 'EUR'})}</h4>
                <h4 style={{color: '#328f2f'}}>Totale entrate: {(this.state.totalEarnings / 100).toLocaleString('it-IT', {style: 'currency', currency: 'EUR'})}</h4>
                <h4 style={{color: '#8f2f2f'}}>Totale uscite: {(this.state.totalExpenses / 100).toLocaleString('it-IT', {style: 'currency', currency: 'EUR'})}</h4>
                <Button onClick={this.printWallet}>Esporta portafoglio</Button>
                    <Grid container style={{
                        justifyContent: 'flex-end'
                    }}>
                        <Grid item>
                            Da
                            <DatePicker locale={'it'} selected={this.state.startDate} onChange={(date) => this.setState({
                                startDate: date
                            })} />
                        </Grid>
                        <Grid item>
                            a
                            <DatePicker locale={'it'} selected={this.state.endDate} onChange={(date) => this.setState({
                                endDate: date
                            })} />
                        </Grid>
                        <Grid item>
                        <Button variant="contained" color="primary" onClick={() => {
                            const contabilities = this.state.backup.filter(contability => (
                                moment(contability.date).isBetween(moment(this.state.startDate, this.state.endDate))
                            ));
                            const totalExpenses = contabilities.filter(x => x.isExpense).filter(x => moment(x.created_at).year() === moment().year()).map(x => x.price).reduce((acc, curr) => acc + curr, 0);
                            const totalEarnings = contabilities.filter(x => !x.isExpense).filter(x => moment(x.created_at).year() === moment().year()).map(x => x.price).reduce((acc, curr) => acc + curr, 0);
                            
                            console.log({contabilities: [...contabilities],
                                totalEarnings: totalEarnings,
                                totalExpenses: totalExpenses,
                                total: totalEarnings - totalExpenses});
                            this.setState({
                                contabilities: [...contabilities],
                                totalEarnings: totalEarnings,
                                totalExpenses: totalExpenses,
                                total: totalEarnings - totalExpenses
                            });
                        }}>Filtra</Button>
                        </Grid>
                    </Grid>
                    
                
                <MaterialTable title="Elenco Movimenti"
                options={{
                    paging:true,
                    pageSize:50,       // make initial page size
                    emptyRowsWhenPaging: false,   // To avoid of having empty rows
                    pageSizeOptions:[50,100,150,200],    // rows selection options
                  }}
                 icons={tableIcons} data={this.state.contabilities} columns={[
                    {
                        title: 'Causale',
                        field: 'causale'
                    },
                    {
                        title: 'Entrata',
                        field: 'price',
                        render: data => <p>{!data.isExpense ? (data.price / 100).toLocaleString('it-IT', {style: 'currency', currency: 'EUR'}) : null}</p>
                    },
                    {
                        title: 'Uscita',
                        field: 'price',
                        render: data => <p>{data.isExpense ? (data.price / 100).toLocaleString('it-IT', {style: 'currency', currency: 'EUR'}) : null}</p>
                    },
                    {
                        title: 'Creato il',
                        field: 'created_at',
                        defaultSort: 'desc',
                        render: data => <p>{data.date ? moment(data.date).format('DD/MM/YYYY') : moment(data.created_at).format('DD/MM/YYYY')}</p>
                    },
                    {
                        title: 'Azioni',
                        field: 'actions',
                        render: data => <Button color="primary" variant="contained" onClick={() => this.props.history.push({pathname: '/trasferisci', password: this.state.codice, id: data.id})}>Trasferisci</Button>
                    }
                ]}></MaterialTable>
                </>
                </div>) : (<div style={{width: 750}}>

            <FormControl fullWidth>

            <FormControl fullWidth>
                <InputLabel id="x">Nome portafoglio</InputLabel>
                <Select
                labelId="x"
                id="demo-simple-select"
                value={this.state.name}
                label="Age"
                onChange={(e) => {this.setState({name: e.target.value}); this.inputRef.current.focus()}}
                >
                    {this.state.portafogli.map(x => (
                        <MenuItem value={x}>{x}</MenuItem>
                    ))}
                </Select>
            </FormControl>
                <TextField autoFocus variant='filled' label="Nome portafoglio da creare" type="text" value={ this.state.name } defaultValue={this.state.name} onChange={(e) => this.setState({name: e.target.value})} inputRef={this.inputRef}></TextField>

                <TextField label="Codice portafoglio" type="text" value={ this.state.codice } defaultValue={ this.state.codice } onChange={(e) => this.setState({codice: e.target.value})}></TextField>
            </FormControl>
                <Button variant="contained" color="primary" onClick={this.getWallet}>
                        Recupera portafoglio
                </Button>

<br /><br />
                <Button variant="contained" color="primary" onClick={this.createWallet} style={{marginTop: 200}}>
                        Crea portafoglio
                </Button>
                </div>)}
              
            </div>
        )
    }
}

export default withRouter(Portafoglio);