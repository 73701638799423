import React, { forwardRef, Text } from 'react';
import Button from '@material-ui/core/Button';
import { withRouter } from 'react-router-dom';
import { FormControl, TextField } from '@material-ui/core';

import MaterialTable from '@material-table/core';
import axios from 'axios';
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import DateFnsUtils from '@date-io/date-fns';
import itLocale from 'date-fns/locale/it';

import IconButton from '@material-ui/core/IconButton';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import moment from 'moment';


const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
  };


class Ordinare extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            products: []
        }
    }
    
    retrieveProducts = async() => {
        const res = await axios.get('https://back.rauccihomedesign.it/api/v1/suppliers/ordered/' + this.props.location.state.id, {
            headers: { Authorization: 'Bearer ' + sessionStorage.getItem('token')}
        })
        this.setState({ products: res.data });
    }
    componentDidMount = async() => {
        await this.retrieveProducts();
    }
    changeRowValue = async (id, estimate, data, index, date = false) => {
       
        const products = this.state.products;
        const product = products.filter(x => x.product === id)[0];
        product[index] = data;
        console.log(products);
        this.setState({
            products: products
        });
        const val = data;

        clearTimeout(this.timer);
        this.timer = setTimeout(async () => {
         if (val) {
            const res = await axios.put('https://back.rauccihomedesign.it/api/v1/products/' + id, {
                [index]: date ? moment(data).format('YYYY-MM-DD') : data,
                estimate_id: estimate
            }, {headers: { Authorization: 'Bearer ' + sessionStorage.getItem('token')}});
         }
        }, 500);
    }

    shipAll = async (data) => {
        if(Object.keys(data).includes('product')){
            const res = await axios.post('https://back.rauccihomedesign.it/api/v1/order', {
                estimate_id: data.estimate_id,
                product: data.product,
                exit: true
            },
            {
                headers: { Authorization: 'Bearer ' + sessionStorage.getItem('token')}
            });

            if(res.status === 200){
                await this.retrieveProducts();
            }
        }else{
            const res = await axios.post('https://back.rauccihomedesign.it/api/v1/order', {
                id: data.row_id
            },
            {
                headers: { Authorization: 'Bearer ' + sessionStorage.getItem('token')}
            });

            if(res.status === 200){
                await this.retrieveProducts();
            }
        }
    }

    goToOrder = (estimate_id) => {
        this.props.history.push({pathname: '/nuovo-contratto', state: {id: estimate_id}})
    }
    render(){
        return (
            <div>
               
                <h1>Fornitori</h1>

                <MaterialTable title="Elenco prodotti ordinata" icons={tableIcons} data={this.state.products} options={{
                        paging:true,
                        pageSize:50,       // make initial page size
                        emptyRowsWhenPaging: false,   // To avoid of having empty rows
                        pageSizeOptions:[50,100,150,200],    // rows selection options
                      }} 
                columns={[
                   {
                    title: 'Cliente',
                    field: 'first_name',
                    render: data => <p onClick={() => this.props.history.push({pathname: 'nuovo-contratto', state: { id: data.estimate_id }})}>{data.first_name} {data.last_name}</p>
                },
                {
                    title: 'Nome prodotto',
                    field: 'name',
                    render: data => <p>{data.name}</p>
                },
                {
                    title: 'Riferimento',
                    field: 'riferimento',
                    render: data => <FormControl fullwidth><TextField label="Riferimento"
                                    onChange={(e) => this.changeRowValue(data.product, e.target.value, 'riferimento', false)} 
                                    value={this.state.products.filter(x => x.product === data.product)[0] ? this.state.products.filter(x => x.product === data.product)[0].riferimento : null}
                                    ></TextField></FormControl>
                },
                {
                    title: 'Quantità',
                    field: 'quantity',
                },
                {
                    title: 'Data di arrivo merce',
                    field: 'data',
                    render: data =>  <FormControl fullwidth>
                                        {console.log(this.state.products.filter(x => x.product === data.product)[0].date_arrival)}
                                        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={itLocale}>
                                            <DatePicker
                                                label="Data arrivo merce"
                                                value={this.state.products.filter(x => x.product === data.product)[0].date_arrival}
                                                defaultValue={this.state.products.filter(x => x.product === data.product)[0].date_arrival}
                                                onChange={(e) => this.changeRowValue(data.product, data.estimate_id, e, 'date_arrival', true)}
                                                animateYearScrolling
                                                fullWidth
                                            />
                                        </MuiPickersUtilsProvider>
                                    
                                    </FormControl>
                },
                {
                    title: 'Data previsto scarico merce',
                    field: 'data',
                    render: data =>  <FormControl fullwidth>
                                        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={itLocale}>
                                            <DatePicker
                                                label="Data previsto scarico merce"
                                                value={this.state.products.filter(x => x.product === data.product)[0].expected_discharge}
                                                onChange={(e) => this.changeRowValue(data.product, data.estimate_id, e, 'expected_discharge', true)}
                                                animateYearScrolling
                                                fullWidth
                                            />
                                        </MuiPickersUtilsProvider>
                                    </FormControl>
                },
                {
                    title: 'In Uscita',
                    field: 'updated_at',
                    render: data => 
                        <div>
                            <IconButton title="Conferma ordine" color="primary" onClick={() => this.shipAll(data)}>
                                <CheckBoxIcon></CheckBoxIcon>
                            </IconButton>
                        </div>
                        
                    
                }
                ]}></MaterialTable>
            </div>
        )
    }
}

export default withRouter(Ordinare);