import React from 'react';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/styles';
import { withRouter } from 'react-router-dom';
class MenuButton extends React.Component {
  state = {
    anchorEl: null
  };

  handleChange = (event, checked) => {
    this.setState({ auth: checked });
  };

  handleMenu = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  handleClick = (href, link = false) => {
    if(link){
      window.open(href);
      return;
    }
    this.props.history.push({pathname: href, state: { id: this.props.data }})
  }
  render() {
    const { classes } = this.props;
    const { auth, anchorEl } = this.state;
    const open = Boolean(anchorEl);
    const listItems = this.props.items.map((link) =>
      <MenuItem onClick={() => this.handleClick(link.href, link.link)} >{link.title}</MenuItem>
    );
    return (
      <div>
       <Button  aria-haspopup="true" onClick={this.handleMenu}>
                                    ...
        </Button>
         
        <Menu
          id="menu-appbar"
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={open}
          onClose={this.handleClose}
        >
        {listItems}


        </Menu>
      </div>
    );
  }

}

export default withRouter(MenuButton);