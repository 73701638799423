import { Grid, TextField, FormControl, Button } from '@material-ui/core';
import React from 'react';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import MuiAlert from '@material-ui/lab/Alert';
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import itLocale from 'date-fns/locale/it';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

class NuovoAcconto extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            days: null, 
            price: null,
            password: null,
            order: null,
            date: new Date()
        }
    }

    addSupplier = async () => {

        if(this.state.password === null || this.state.password === ''){
            alert('Devi inserire un codice portafoglio per poter procedere');
            return;
        }
        let responseOrderMovement = await axios.post('https://back.rauccihomedesign.it/api/v1/order-movements',
            {
                causale: ['Acconto per ordine ' + this.props.location.state.id].join(' '),
                isExpense: false,
                price: this.state.price,
                order: this.props.location.state.id,
                date: this.state.date
            },
            {
                headers: { Authorization: 'Bearer ' + sessionStorage.getItem('token')}
            }
        );
        
        let responseWallet = await axios.post('https://back.rauccihomedesign.it/api/v1/contabilities', {
                causale: ['Acconto per ordine ' + this.state.order.name, this.state.order.surname].join(' '),
                isExpense: false,
                price: this.state.price,
                password: this.state.password,
                date: this.state.date,
            },
            {
                headers: { Authorization: 'Bearer ' + sessionStorage.getItem('token')}
            }
        );

        this.props.history.goBack();

    }

    componentDidMount = async() => {
        
        let order = await axios.get('https://back.rauccihomedesign.it/api/v1/estimates/' + this.props.location.state.id, {
            headers: { Authorization: 'Bearer ' + sessionStorage.getItem('token')}
        });

        this.setState({order: order.data});

    }
    render(){
        return (
            <div>
                <h1>Inserisci nuovo movimento</h1>
                <Grid container spacing={3}>

                    <Grid item xs={6}>
                        <h2>Dati Movimento</h2>

                        <FormControl fullWidth>
                            <TextField type="number" label="Valore acconto" value={this.state.price} onInput={(e) => this.setState({price: e.target.value})}></TextField> 
                        </FormControl>
                        <FormControl fullWidth>
                            <TextField type="string" label="Password Portafoglio" value={this.state.password} onInput={(e) => this.setState({password: e.target.value})}></TextField>
                        </FormControl>
                        <FormControl fullwidth>
                        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={itLocale}>
                            <DatePicker
                                label="Data acconto"
                                value={this.state.date}
                                onChange={(e) => this.setState({date: e})}
                                animateYearScrolling
                                fullWidth
                            />
                        </MuiPickersUtilsProvider>
                      
                        </FormControl>
                    </Grid>


                    
                </Grid>
                <Button variant="contained" color="primary" onClick={this.addSupplier} style={{marginTop: 32}}>
                        Aggiungi Movimento
                </Button>

               
            </div>
        )
    }
}

export default withRouter(NuovoAcconto);