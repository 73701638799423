import React, { forwardRef, Text } from 'react';
import Button from '@material-ui/core/Button';
import { withRouter } from 'react-router-dom';

import MaterialTable from '@material-table/core';
import axios from 'axios';
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';

import IconButton from '@material-ui/core/IconButton';
import PencilIcon from '@material-ui/icons/Edit';
import PostAddIcon from '@material-ui/icons/PostAdd';
import PrinterIcon from '@material-ui/icons/Print';
import DeleteIcon from '@material-ui/icons/Delete';
import { ButtonGroup, FormControlLabel, FormGroup } from '@material-ui/core';
import CheckBox from '@material-ui/icons/CheckBox';
import moment from 'moment';
import Alert from '@material-ui/lab/Alert';
import FmdBadIcon from '@mui/icons-material/FmdBad';

const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
  };


class Preventivi extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            estimates: [],
            estimates_backup: [],
            suspended: false
        }
    }
    
    componentDidMount = async() => {
        const res = await axios.get('https://back.rauccihomedesign.it/api/v1/estimates?status=0', {
            headers: { Authorization: 'Bearer ' + sessionStorage.getItem('token')}
        })
        
        this.setState({ estimates: res.data.sort((a,b ) => b.id - a.id).filter(estimate => moment().diff(moment(estimate.updated_at), 'days') < 90), estimates_backup:  res.data.sort((a,b ) => b.id - a.id)});
    }

    deleteContract = async(id) => {
        if(window.confirm('Sei sicuro di voler cancellare il contratto?') == true){
            const res = await axios.delete('https://back.rauccihomedesign.it/api/v1/estimates/' + id, {
                headers: { Authorization: 'Bearer ' + sessionStorage.getItem('token')}
            });
            if(res.status === 200){
                this.setState({
                    estimates: this.state.estimates.filter(x => x.estimate_id !== id)
                })
            }
        }
       
    }
    onlySuspended = (stato) => {
        switch(stato){
            case 1: 
                this.setState({estimates: [...this.state.estimates_backup.filter((data) => data.converted === true)]});
                break;
            case 2:
                this.setState({estimates: [...this.state.estimates_backup.filter((data) => data.converted === false)]});
                break;
            case 3:
                this.setState({estimates: [...this.state.estimates_backup.filter(estimate => moment().diff(moment(estimate.updated_at), 'days') > 90 && !estimate.converted)]});
                break;
            case 4:
                this.setState({estimates: [...this.state.estimates_backup.filter(estimate => moment().diff(moment(estimate.updated_at), 'days') > 7 && moment().diff(moment(estimate.updated_at), 'days') < 90 && !estimate.converted)]});
                break;
            case 5:
                this.setState({estimates: [...this.state.estimates_backup]});
                break;
        }
    }
    render(){
        return (
            <div>
                <Button variant="contained" color="primary" onClick={() => this.props.history.push('nuovo-preventivo')}  style={{marginBottom: 36}}>
                    Crea preventivo
                </Button>
                <h1>Preventivi</h1>

                <Alert style={{width: 550, marginBottom: 24}} severity="warning">
                    Ci sono {this.state.estimates_backup.filter(estimate => moment().diff(moment(estimate.updated_at), 'days') > 90).length} preventivi in stato sospeso (non aggiornati per 90 giorni)
                </Alert>
                <ButtonGroup variant="contained">
                    <Button onClick={() => this.onlySuspended(1)}>Mostra convertiti</Button>
                    <Button onClick={() => this.onlySuspended(2)}>Mostra non convertiti</Button>
                    <Button onClick={() => this.onlySuspended(3)}>Mostra sospesi</Button>
                    <Button onClick={() => this.onlySuspended(4)}>Mostra remind 7 giorni</Button>
                    <Button onClick={() => this.onlySuspended(5)}>Mostra tutti</Button>

                </ButtonGroup>

                <MaterialTable title="Elenco Preventivi"
                options={{
                    paging:true,
                    pageSize:50,       // make initial page size
                    emptyRowsWhenPaging: false,   // To avoid of having empty rows
                    pageSizeOptions:[50,100,150,200],    // rows selection options
                    rowStyle: rowData => ({
                        backgroundColor: rowData.converted ? '#7CFC00' : '#fff'
                    })

                  }}
                
                icons={tableIcons} data={this.state.estimates}
                columns={[
                    {
                        title: 'Protocollo',
                        field: 'id',
                        render: data => <p>{data.progressive}/{data.year_created}</p>
                    },
                    {
                        title: 'Nome cliente',
                        field: 'name',
                        render: data => <p>{data.name} {data.surname} {moment().diff(moment(data.updated_at), 'days') > 7 && !data.converted ? <><br /><FmdBadIcon htmlColor='#F6BE00' /></> : null}</p>,
                        customFilterAndSearch: (term, data) => (data.name.toLowerCase().includes(term.toLowerCase()) || data.surname.toLowerCase().includes(term.toLowerCase()))
                    },
                    {
                        title: 'Via',
                        field: 'address'
                    },
                    {
                        title: 'Città',
                        field: 'city'
                    },
                    {
                        title: 'Telefono',
                        field: 'phone_number'
                    },
                    {
                        title: 'E-mail',
                        field: 'email'
                    },
                    {
                        title: 'Totale preventivo',
                        field: 'price',
                        render: data => <p>{(data.price / 100).toLocaleString('it-IT', {style: 'currency', currency: 'EUR'})}</p>
                    },
                    {
                        title: 'Data creazione',
                        field: 'created_at',
                        render: data => <p>{new Date(data.created_at).toLocaleDateString('it-IT')}</p>
                    },
                    {
                        title: 'Azioni',
                        field: 'updated_at',
                        render: data => 
                            <div style={{display: 'flex'}}>
                                <IconButton aria-label="Modifica" title="Modifica" onClick={() => this.props.history.push({pathname: '/nuovo-preventivo', state: {id: data.estimate_id}})}>
                                    <PencilIcon></PencilIcon>
                                </IconButton>
                                <IconButton aria-label="Transforma" title="Transforma in contratto" onClick={() => this.props.history.push({pathname: '/nuovo-contratto', state: {id: data.estimate_id, new_contract: true}})}>
                                    <PostAddIcon></PostAddIcon>
                                </IconButton>
                                <IconButton aria-label="print" title="Stampa" onClick={() => window.open('https://back.rauccihomedesign.it/pdf/' + data.estimate_id)}>
                                    <PrinterIcon></PrinterIcon>
                                </IconButton>
                                <IconButton aria-label="print" title="Rimuovi" onClick={() => this.deleteContract(data.estimate_id)}>
                                    <DeleteIcon></DeleteIcon>
                                </IconButton>
                            </div>
                            
                        
                    }
                ]
                    }></MaterialTable>
            </div>
        )
    }
}

export default withRouter(Preventivi);