import { Grid, TextField, FormControl, Select, MenuItem, InputLabel, Button } from '@material-ui/core';
import React from 'react';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

class Spesa extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            price: null,
            password: null
        }
    }

    addSupplier = async () => {
       
        
        let responseWallet = await axios.put('https://back.rauccihomedesign.it/api/v1/contabilities/' + this.props.location.id, {
                password: this.state.password
            },
            {
                headers: { Authorization: 'Bearer ' + sessionStorage.getItem('token')}
            }
        );

        this.props.history.push({pathname: '/portafoglio'});

    }

    componentDidMount = async() => {
        

    }
    render(){
        return (
            <div>
                <h1>Inserisci nuovo movimento</h1>
                <Grid container spacing={3}>

                    <Grid item xs={6}>
                        <h2>Dati Trasferimento</h2>
                        <FormControl fullWidth>
                            <TextField type="text" label="Codice portafoglio" value={this.state.password} onInput={(e) => this.setState({password: e.target.value})}></TextField> 
                        </FormControl>
                        
                    </Grid>


                    
                </Grid>
                <Button variant="contained" color="primary" onClick={this.addSupplier} style={{marginTop: 32}}>
                        Aggiungi Movimento
                </Button>

               
            </div>
        )
    }
}

export default withRouter(Spesa);