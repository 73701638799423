import React from "react";
import {
    Redirect
  } from "react-router-dom";
import Helper from "../Helper";
import Layout from "../pages/Layout";
import {
  BrowserRouter as RouterV,
  Switch,
  Route,
  Link,
} from "react-router-dom";

  export default class AuthenticatedRoute extends React.Component{
        constructor(props){
            super(props);
            this.loggedIn = Helper.isLoggedIn();
        }
        render(){
          return(
            <Route render={() => { return this.loggedIn ? <Layout child={this.props.child} /> : <Redirect to="/accedi" />}} />);
        }
  }