import React, { forwardRef, Text } from 'react';
import Button from '@material-ui/core/Button';
import { withRouter } from 'react-router-dom';

import MaterialTable from '@material-table/core';
import axios from 'axios';
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';

import IconButton from '@material-ui/core/IconButton';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

import {TextField, Checkbox, FormControlLabel} from '@material-ui/core';
import SwitchBase from '@material-ui/core/internal/SwitchBase';

import moment from 'moment';

const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
  };


class InUscita extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            products: []
        }
    }
    setShippedValue = async (value, index, id) => {
        const _back = this.state.products;
        _back[index].inExit = value;
        _back[index].arrivalDate = moment().format('YYYY-MM-DD H:m:s');
        await this.setState({products: _back});

        const res = await axios.put('https://back.rauccihomedesign.it/api/v1/products/' + id, {
            inExit: value,
            arrivalDate: moment().format('YYYY-MM-DD H:m:s')

        }, {
            headers: { Authorization: 'Bearer ' + sessionStorage.getItem('token')}
        });
    }
    retrieveProducts = async() => {
        const res = await axios.get('https://back.rauccihomedesign.it/api/v1/suppliers/exiting/' + this.props.location.state.id, {
            headers: { Authorization: 'Bearer ' + sessionStorage.getItem('token')}
        })
        
        this.setState({ products: res.data});
    }
    componentDidMount = async() => {
        await this.retrieveProducts();
    }

    shipAll = async (data) => {
        if(Object.keys(data).includes('product')){
            const res = await axios.post('https://back.rauccihomedesign.it/api/v1/order', {
                estimate_id: data.estimate_id,
                product: data.product,
                exit: true
            },
            {
                headers: { Authorization: 'Bearer ' + sessionStorage.getItem('token')}
            });

            if(res.status === 200){
                await this.retrieveProducts();
            }
        }else{
            const res = await axios.post('https://back.rauccihomedesign.it/api/v1/order', {
                id: data.row_id
            },
            {
                headers: { Authorization: 'Bearer ' + sessionStorage.getItem('token')}
            });

            if(res.status === 200){
                await this.retrieveProducts();
            }
        }
    }

    goToOrder = (estimate_id) => {
        this.props.history.push({pathname: '/nuovo-contratto', state: {id: estimate_id}})
    }

    
    setNote = (index, value) => {
        console.log(index, value);
        const _back = this.state.products;
        _back[index].shipment_notes = value;
        this.setState({products: _back});
    }

    saveNotes = async(id, index) => {
       
        const res = await axios.put('https://back.rauccihomedesign.it/api/v1/products/' + id, {
            notes: this.state.products[index].shipment_notes

        }, {
            headers: { Authorization: 'Bearer ' + sessionStorage.getItem('token')}
        });

    }
    render(){
        return (
            <div>
               
                <h1>Fornitori</h1>

                <MaterialTable title="Elenco prodotti in uscita" icons={tableIcons} options={{
                        paging:true,
                        pageSize:50,       // make initial page size
                        emptyRowsWhenPaging: false,   // To avoid of having empty rows
                        pageSizeOptions:[50,100,150,200],    // rows selection options
                      }}
                    data={this.state.products} columns={[
                    {
                        title: 'Nome prodotto',
                        field: 'name',
                        render: data => <p onClick={() => this.goToOrder(data.estimate_id)}>{data.name}</p>
                    },
                    {
                        title: 'Fornitore',
                        field: 'supplier_name',
                    },
                    {
                        title: 'Quantità',
                        field: 'quantity',
                    },
                    {
                        title: 'Note',
                        field: 'notes',
                        render: (data) => <TextField onMouseLeave={() => this.saveNotes(data.id, data.tableData.id)} value={this.state.products[data.tableData.id].shipment_notes} onInput={(e) => this.setNote(data.tableData.id, e.target.value)}></TextField>
                    },
                    {
                        title: 'Spedito',
                        field: 'shipped',
                        render: (data) => (data.arrival_date ? <TextField value={data.arrival_date} disabled></TextField> : <FormControlLabel
                            control={
                                <Checkbox
                                    checked={this.state.products[data.tableData.id].arrivalDate}
                                    onChange={(e) => this.setShippedValue(e.target.checked, data.tableData.id, data.id)}
                                    name="shipping"
                                    color="primary"
                                />
                            }
                            label="In uscita"
                        />)
                    }
                ]}></MaterialTable>
            </div>
        )
    }
}

export default withRouter(InUscita);