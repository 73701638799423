import React, {forwardRef} from 'react';
import Button from '@material-ui/core/Button';
import { withRouter } from 'react-router-dom';
import MaterialTable from '@material-table/core';
import axios from 'axios';
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import { Grid } from '@material-ui/core';
import moment from 'moment';
const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
  };


class Movimenti extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            movements: [],
            order: null,
            columns: [
                {
                    title: 'Causale',
                    field: 'causale'
                },
                {
                    title: 'Tipo',
                    field: 'isExpense',
                    render: data => <p>{data.isExpense ? 'Uscita' : 'Entrata'}</p>
                },
               
                {
                    title: 'Creato il',
                    field: 'created_at',
                    render: data => <p>{moment(data.date).format('DD/MM/YYYY')}</p>
                },
                {
                    title: 'Valore',
                    field: 'price',
                    render: data => <p>{(data.price / 100).toLocaleString('it-IT', {style: 'currency', currency: 'EUR'})}</p>
                }
            ]
        }
    }
    
    componentDidMount = async () => {
        let orderMovements = await axios.get('https://back.rauccihomedesign.it/api/v1/order-movements/' + this.props.location.state.id, {
            headers: { Authorization: 'Bearer ' + sessionStorage.getItem('token')}
        });
        console.log(orderMovements);
        if(this.props.showExpenses !== undefined){
            if(this.props.showExpenses){
                let movementsData = orderMovements.data;
                movementsData = movementsData.filter(x => x.isExpense == this.props.expense);
                if(this.props.workers){
                    movementsData = movementsData.filter(x => x.causale.includes('Movimentazione operaio'));
                }else{
                    this.state.columns.push({
                        title: 'Agente',
                        field: 'agent'
                    });
                }
                orderMovements.data = movementsData;
            }
        }
        this.setState({movements: orderMovements.data});

        let order = await axios.get('https://back.rauccihomedesign.it/api/v1/estimates/' + this.props.location.state.id, {
            headers: { Authorization: 'Bearer ' + sessionStorage.getItem('token')}
        });
        this.setState({order: order.data});
    }

    render(){
        return (
            <div>
              
                <Grid container spacing={1}>
                    {this.props.workers ?  <Grid item xs={2}>
                        <Button variant="contained" color="primary" onClick={() => this.props.history.push({pathname: '/aggiungi-movimento', state: { id: this.props.location.state.id }})}>
                            Aggiungi Movimento
                        </Button>
                       
                    </Grid> : <Grid item xs={2}>
                        <Button variant="contained" color="primary" onClick={() => this.props.history.push({pathname: '/aggiungi-acconto', state: { id: this.props.location.state.id }})}>
                            Aggiungi Acconto
                        </Button>
                    </Grid>}
                   
                    
                </Grid>
                
                <h1>Movimenti</h1>
                <MaterialTable title="Elenco Movimenti" icons={tableIcons} data={this.state.movements} columns={this.state.columns}></MaterialTable>
            </div>
        )
    }
}

export default withRouter(Movimenti);