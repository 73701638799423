import { GoogleApiWrapper, Map, Marker } from "google-maps-react";

function CustomMap({ google, locations = {}}) {
    console.log(locations)
    return (
        <Map
            google={google}
            containerStyle={{
                position: "static",
                width: "100%",
                height: "100%"
            }}
            style={{
                width: "100%",
                height: "100%"
            }}
            center={locations}
            initialCenter={locations}
            zoom={17}
            disableDefaultUI={true}

        >
               <Marker position={locations} />
           

        </Map>
    )
};

export default GoogleApiWrapper({
    apiKey: "AIzaSyDuL4g1jxfDZVxXP_XLj46sHywY-ShsObc"
})(CustomMap);