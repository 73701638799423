import { Grid, TextField, FormControl, Select, MenuItem, InputLabel, Button } from '@material-ui/core';
import React from 'react';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

class NuovoOperaio extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            name: "",
            price: "",
            overtime_price: "",
            open: false,
            status: 'success',
            message: ""
        }
    }

    addSupplier = async () => {
        let response = await axios.post('https://back.rauccihomedesign.it/api/v1/workers',
            {
                name: this.state.name,
                price: this.state.price,
                overtime_price: this.state.overtime_price
            },
            {
                headers: { Authorization: 'Bearer ' + sessionStorage.getItem('token')}
            }
        );
        
        
        this.props.history.push('operai');
    }
    render(){
        return (
            <div>
                <h1>Inserisci nuovo operaio</h1>
                <Grid container spacing={3}>

                    <Grid item xs={6}>
                        <h2>Dati Operaio</h2>
                        <FormControl fullWidth>
                            <TextField label="Nome" value={this.state.name} onInput={(e) => this.setState({name: e.target.value})}></TextField>
                        </FormControl>
                        <FormControl fullWidth>
                            <TextField type="number" label="Prezzo regolare" value={this.state.price} onInput={(e) => this.setState({price: e.target.value})}></TextField> 
                        </FormControl>
                        <FormControl fullWidth>
                            <TextField type="number" label="Prezzo straordinari" value={this.state.overtime_price} onInput={(e) => this.setState({overtime_price: e.target.value})}></TextField>
                        </FormControl>

                    </Grid>


                    
                </Grid>
                <Button variant="contained" color="primary" onClick={this.addSupplier} style={{marginTop: 32}}>
                        Aggiungi operaio
                </Button>

                <Snackbar open={this.state.open} autoHideDuration={6000} onClose={() => this.setState({open: false})}>
                    <Alert onClose={() => this.setState({open: false})} severity="success">
                        {this.state.message}
                    </Alert>
                </Snackbar>
            </div>
        )
    }
}

export default withRouter(NuovoOperaio);