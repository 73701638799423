import { Grid, TextField, FormControl, Select, MenuItem, InputLabel, Button, Checkbox, FormControlLabel, FormGroup, IconButton } from '@material-ui/core';
import React from 'react';
import { withRouter} from 'react-router-dom';
import axios from 'axios';
import CancelIcon from '@material-ui/icons/Cancel';
import Autocomplete from '@material-ui/lab/Autocomplete';

class NuovoPreventivo extends React.Component{
    
    constructor(props){
        super(props);
        this.state = {
            products: [],
            customer: {
                first_name: "",
                last_name: "",
                email: "",
                address: "",
                city: "",
                province: "",
                postcode: "",
                company_name: "",
                vat_number: "",
                codice_univoco: "",
                phone_number: ""
            },
            handles: [],
            allProducts: [],
            allHandles: [],
            mounting: false,
            shipping: false,
            shop: "",
            iva: [
                '4%',
                '10%',
                '22%'
            ]
        }

        
    }

    retrieveCustomer = async() => {
        const id = this.props.location.customer ? this.props.location.customer : null;

        if(id){
            const response = await axios.get('https://back.rauccihomedesign.it/api/v1/customers/' + id,
            {
                headers: { Authorization: 'Bearer ' + sessionStorage.getItem('token')}
            });
            const customer = {
                first_name: response.data.name,
                last_name: response.data.surname,
                email: response.data.email,
                address: response.data.address,
                city: response.data.city,
                province: response.data.province,
                postcode: response.data.postcode,
                company_name: response.data.company_name,
                vat_number: response.data.vat_number,
                codice_univoco: response.data.codice_univoco,
                phone_number: response.data.phone_number,
                id: response.data.customer_id,
            };

            this.setState({
                customer: customer
            })
        }
    }

    retrieveHandles = async() => {
        const res = await axios.get('https://back.rauccihomedesign.it/api/v1/handles', {
            headers: { Authorization: 'Bearer ' + sessionStorage.getItem('token')}
        });

        if(res.status === 200){
            this.setState({
                allHandles: res.data.map(x => x.name)
            });

        }
    }
    retrieveProducts = async() => {
        const res = await axios.get('https://back.rauccihomedesign.it/api/v1/products', {
            headers: { Authorization: 'Bearer ' + sessionStorage.getItem('token')}
        });

        if(res.status === 200){
            this.setState({
                allProducts: res.data.map(x => x.name)
            });
        }
    }
    retrieveEstimate = async() => {
        const id = this.props.location.state ? this.props.location.state.id : null;
        if(id){
            const estimate = await axios.get('https://back.rauccihomedesign.it/api/v1/estimates/' + id +'?status=' + 0,
            {
                headers: { Authorization: 'Bearer ' + sessionStorage.getItem('token')}
            }
            );
            const customer = {
                first_name: estimate.data.name,
                last_name: estimate.data.surname,
                email: estimate.data.email,
                address: estimate.data.address,
                city: estimate.data.city,
                province: estimate.data.province,
                postcode: estimate.data.postcode,
                company_name: estimate.data.company_name,
                vat_number: estimate.data.vat_number,
                codice_univoco: estimate.data.codice_univoco,
                phone_number: estimate.data.phone_number,
                id: estimate.data.customer_id,
            }; 
            console.log(estimate.data);

            this.setState({
                products: estimate.data.products,
                customer: customer,
                handles: estimate.data.handles,
                shop: estimate.data.shop,
                mounting: estimate.data.mounting,
                shipping: estimate.data.shipping
            });

     
        }
    }
    addRow = () => {
        const products = this.state.products;
        products.push({
            iva: 22
        });

        this.setState({products: products});

        window.setTimeout(() => {
            window.scrollTo(0, document.body.scrollHeight);    
        },
            100)
    }
    addRowHandles = () => {
        const handles = this.state.handles;
        handles.push({});

        this.setState({handles: handles});
    }

    setCustomerData = (key, value) => {
        let customerData = this.state.customer;
        customerData[key] = value;
        this.setState({customer: customerData});
    }

    setProductData = (index, value, key) => {
        let productsData = this.state.products;
        productsData[index][key] = value;
        this.setState({products: productsData});
        console.log(key, value);
    }   

    setHandleData = (index, value, key) => {
        let handleData = this.state.handles;
        handleData[index][key] = value;
        this.setState({handles: handleData});
    }   
    createEstimate = async () => {
        
        for(let idx in this.state.customer){
            if(this.state.customer[idx] === "" && idx !== "vat_number" && idx !== "codice_univoco" && idx !== "company_name"){
                alert('Devi compilare il campo ' + idx);
                return;
            }
        }
        //CHECK FIELDS
        if(this.state.customer.vat_number && this.state.customer.codice_univoco){
            if(this.state.customer.vat_number !== "" && this.state.customer.codice_univoco !== ""){
                if((this.state.customer.vat_number.length !== 11 || this.state.customer.vat_number.length !== 16) || this.state.customer.codice_univoco.length !== 7){
                    alert("La partita iva o il codice univoco non sono corretti");
                    return;
                }
            }
        }
        
        if(this.state.customer.province.length !== 2){
            alert("La provincia deve essere di 2 caratteri");
            return;
        }

        if(this.state.customer.postcode.length !== 5){
            alert("Il codice postale deve essere di 5 caratteri");
            return;
        }

        if(this.state.shop === ""){
            alert("Il campo negozio è obbligatorio");
            return;
        }

        const products = this.state.products.map(x => !x.iva ? {...x, iva: 22} : x);
        
        let id = this.props.location.customer;
        if(!id){
            const res = await axios.post('https://back.rauccihomedesign.it/api/v1/customers', 
                this.state.customer,
                {
                    headers: { Authorization: 'Bearer ' + sessionStorage.getItem('token')}
                }
            );

            id = res.data.id
        }
        

            let createEstimate = await axios.post('https://back.rauccihomedesign.it/api/v1/estimates', 
            {
                handles: this.state.handles,
                products: products,
                customer_id: id,
                store: this.state.shop,
                mounting: this.state.mounting,
                shipping: this.state.shipping,
                destination: this.state.destination,
            },
            {
                headers: { Authorization: 'Bearer ' + sessionStorage.getItem('token')}
            });

            if(createEstimate.status !== 200) console.error('diocca');
            else this.props.history.push('/preventivi');
    }

    deleteRow = (index) => {
        let cachedArray = this.state.products;
        let newArray = cachedArray.filter((o, i) => i !== index);
        this.setState({
            products: newArray
        });
    }
    deleteRowHandle = (index) => {
        let cachedArray = this.state.handles;
        let newArray = cachedArray.filter((o, i) => i !== index);
        this.setState({
            handles: newArray
        });
    }

    editEstimate = async() => {
        const customerResponse = await axios.put('https://back.rauccihomedesign.it/api/v1/customers/' + this.state.customer.id, this.state.customer,
        {
            headers: { Authorization: 'Bearer ' + sessionStorage.getItem('token')}
        });

        let updateEstimate = await axios.put('https://back.rauccihomedesign.it/api/v1/estimates/' + this.props.location.state.id,
        {
            handles: this.state.handles,
            products: this.state.products,
            store: this.state.shop,
            shipping: this.state.shipping,
            mounting: this.state.mounting,
            status: 0
        },
        {
            headers: { Authorization: 'Bearer ' + sessionStorage.getItem('token')}
        });

        if(updateEstimate.status !== 200) console.error('diocca');
        else this.props.history.push('/preventivi');
    }
    editEstimateStay = async() => {
        const customerResponse = await axios.put('https://back.rauccihomedesign.it/api/v1/customers/' + this.state.customer.id, this.state.customer,
        {
            headers: { Authorization: 'Bearer ' + sessionStorage.getItem('token')}
        });
        let updateEstimate = await axios.put('https://back.rauccihomedesign.it/api/v1/estimates/' + this.props.location.state.id,
        {
            handles: this.state.handles,
            products: this.state.products,
            store: this.state.shop,
            shipping: this.state.shipping,
            mounting: this.state.mounting,
            status: 0
        },
        {
            headers: { Authorization: 'Bearer ' + sessionStorage.getItem('token')}
        });

    }
    componentDidMount = async() => {
        await this.retrieveCustomer();
        await this.retrieveEstimate();
        await this.retrieveProducts();
        await this.retrieveHandles();
    }   

    render(){
        return (
            <div>
                <h1>Inserisci nuovo preventivo</h1>
                <Button variant="contained" color="primary" onClick={this.props.location.state ? this.editEstimate : this.createEstimate} style={{marginTop: 32}}>
                        {this.props.location.state ? 'Modifica preventivo' : 'Crea preventivo'}
                </Button>
               
                
                <Grid container spacing={3}>

                    <Grid item xs={6}>
                        <h2>Dati Cliente</h2>
                        <FormControl fullWidth>
                            <TextField label="Nome" value={this.state.customer.first_name} onInput={(e) => this.setCustomerData('first_name', e.target.value)} required></TextField>
                        </FormControl>
                        <FormControl fullWidth>
                            <TextField label="Cognome" value={this.state.customer.last_name} onInput={(e) => this.setCustomerData('last_name', e.target.value)} required></TextField>
                        </FormControl>
                        <FormControl fullWidth>
                            <TextField label="E-mail" value={this.state.customer.email} onInput={(e) => this.setCustomerData('email', e.target.value)} required></TextField>
                        </FormControl>
                        <FormControl fullWidth>
                            <TextField label="Numero di Telefono" value={this.state.customer.phone_number} onInput={(e) => this.setCustomerData('phone_number', e.target.value)} required></TextField>
                        </FormControl>
                        <FormControl fullWidth>
                            <TextField label="Indirizzo" value={this.state.customer.address} onInput={(e) => this.setCustomerData('address', e.target.value)} required></TextField>
                        </FormControl>
                        <FormControl fullWidth>
                            <TextField label="Città" value={this.state.customer.city} onInput={(e) => this.setCustomerData('city', e.target.value)} required></TextField>
                        </FormControl>
                        <FormControl fullWidth>
                            <TextField label="Provincia" value={this.state.customer.province} onInput={(e) => this.setCustomerData('province', e.target.value)} required></TextField> 
                        </FormControl>
                        <FormControl fullWidth>
                            <TextField label="Codice postale" value={this.state.customer.postcode} onInput={(e) => this.setCustomerData('postcode', e.target.value)} required></TextField>
                        </FormControl>
                        <FormControl fullWidth>
                            <TextField label="Ragione sociale" value={this.state.customer.company_name} onInput={(e) => this.setCustomerData('company_name', e.target.value)}></TextField> 
                        </FormControl>
                        <FormControl fullWidth>
                            <TextField label="P.IVA / Codice Fiscale" value={this.state.customer.vat_number} onInput={(e) => this.setCustomerData('vat_number', e.target.value)}></TextField>
                        </FormControl>
                        <FormControl fullWidth>
                            <TextField label="Codice Univoco" value={this.state.customer.codice_univoco} onInput={(e) => this.setCustomerData('codice_univoco', e.target.value)}></TextField>
                        </FormControl>

                    </Grid>
                    <Grid item xs={6}>
                        <h2>Anagrafica preventivo</h2>
                        <FormControl fullWidth>
                            
                            <InputLabel id="demo-simple-select-helper-label">Negozio</InputLabel>
                            <Select label="Negozio" value={this.state.shop} onChange={(e) => this.setState({shop: e.target.value})}>
                                <MenuItem value="Santa Maria Capua Vetere">Santa Maria Capua Vetere</MenuItem>
                                <MenuItem value="Marcianise">Marcianise</MenuItem>
                            </Select>
                        </FormControl>
                        <FormGroup row>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={this.state.shipping}
                                        onChange={() => this.setState({shipping: !this.state.shipping})}
                                        name="shipping"
                                        color="primary"
                                    />
                                }
                                label="Trasporto incluso"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={this.state.mounting}
                                        onChange={() => this.setState({mounting: !this.state.mounting})}
                                        name="shipping"
                                        color="primary"
                                    />
                                }
                                label="Posa in opera"
                            />
                        </FormGroup>
                    </Grid>

                    <Grid item xs={12}>
                    <Button variant="contained" color="primary" onClick={this.props.location.state ? this.editEstimate : this.createEstimate} style={{marginTop: 32}}>
                        {this.props.location.state ? 'Modifica preventivo' : 'Crea preventivo'}
                    </Button>
                    {this.props.location.state ? (<Button variant="contained" color="primary" onClick={this.editEstimateStay} style={{marginTop: 32, marginLeft: 12}}>
                        Aggiorna Preventivo
                    </Button>) : null}
                        <h2>Prodotti</h2>
                    
                        {this.state.products.map((row, index) => 
                            <Grid container spacing={2} style={{paddingTop: 24}}>
                                <Grid item xs={5}>
                                    <FormControl fullWidth >
                                        <TextField maxRows={3} multiline label="Nome prodotto" type="text" value={this.state.products[index].name} onChange={(e) => this.setProductData(index, e.target.value, "name")}></TextField>
                                    </FormControl>
                                    
                                </Grid>
                                <Grid item xs={1}>
                                    <FormControl fullWidth>
                                        <TextField label="Prezzo" type="number" value={this.state.products[index].price / 100} onChange={(e) => this.setProductData(index, parseInt(parseFloat(e.target.value.replace('-', '-')) * 100), "price")}></TextField>
                                    </FormControl>
                                    
                                </Grid>
                                <Grid item xs={1}>
                                    <FormControl fullWidth>
                                        <TextField label="Quantità" type="number" value={this.state.products[index].quantity} onChange={(e) => this.setProductData(index, e.target.value, "quantity")}></TextField>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={2}>
                                    <FormControl fullWidth>
                                        <TextField maxRows={3} multiline label="Note" type="text" value={this.state.products[index].notes} onChange={(e) => this.setProductData(index, e.target.value, "notes")}></TextField>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={1}>
                                    <FormControl fullWidth >
                                        <TextField label="Subtotale" type="number" value={this.state.products[index].quantity * this.state.products[index].price / 100} disabled></TextField>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={1}>
                                    <FormControl fullWidth >
                                        <Autocomplete freeSolo options={this.state.iva}   renderInput={(params) => <TextField {...params} label="IVA"/>} onChange={(e, value) => this.setProductData(index, value.split('%')[0], 'iva')} defaultValue="22%" />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={1}>
                                    <IconButton onClick={() => this.deleteRow(index)} style={{marginTop: 8}}>
                                        <CancelIcon htmlColor="#ff0000"/>
                                    </IconButton>
                                </Grid>
                            </Grid>    
                        )}

                    </Grid>
                    
                  
                </Grid>
                <Button variant="contained" color="primary" onClick={() => this.addRow()}>
                            Aggiungi Prodotto
                    </Button>
                    {this.props.location.state ? (<Button variant="contained" color="primary" onClick={this.editEstimateStay} style={{marginLeft: 12}}>
                        Aggiorna Preventivo
                </Button>) : null}
            </div>
        )
    }
}

export default withRouter(NuovoPreventivo);