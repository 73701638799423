import { Grid, TextField, FormControl, Select, MenuItem, InputLabel, Button } from '@material-ui/core';
import React from 'react';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

class NuovoMovimento extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            days: null,
            open: false,
            status: 'success',
            message: "",
            workers: [],
            worker: null,
            overtime: null,
        }
    }

    addSupplier = async () => {
        const worker = this.state.workers[this.state.worker];
        let responseOrderMovement = await axios.post('https://back.rauccihomedesign.it/api/v1/order-movements',
            {
                causale: ['Movimentazione operaio ' + worker.name + ' per ' + this.state.days + ' giorni', parseInt(this.state.overtime) > 0 ? ' e  ' + this.state.overtime + ' ore di straordinario' : null].join(' '),
                isExpense: true,
                price: (parseInt(this.state.days) * parseInt(worker.price)) + (parseInt(this.state.overtime) * parseInt(worker.overtime_price)),
                order: this.props.location.state.id
            },
            {
                headers: { Authorization: 'Bearer ' + sessionStorage.getItem('token')}
            }
        );
        
        this.props.history.push({pathname: '/movimenti', state: {id: this.props.location.state.id}});
    }

    componentDidMount = async() => {
        let workers = await axios.get('https://back.rauccihomedesign.it/api/v1/workers', {
            headers: { Authorization: 'Bearer ' + sessionStorage.getItem('token')}
        });

        this.setState({workers: workers.data});
        console.log(this.props.location);

    }
    render(){
        return (
            <div>
                <h1>Inserisci nuovo movimento</h1>
                <Grid container spacing={3}>

                    <Grid item xs={6}>
                        <h2>Dati Movimento</h2>

                        <FormControl fullWidth>
                            <label>Seleziona operaio</label>
                            <Select label="Seleziona Operaio" value={this.state.worker} placeholder="Seleziona Operaio" onChange={(e) => {this.setState({worker: e.target.value});}}>
                                {
                                    this.state.workers.map((e, i) => <MenuItem value={i} >{e.name}</MenuItem>)
                                }
                            </Select>
                        </FormControl>
                        <FormControl fullWidth>
                            <TextField type="number" label="Giorni" value={this.state.days} onInput={(e) => this.setState({days: e.target.value})}></TextField> 
                        </FormControl>
                        <FormControl fullWidth>
                            <TextField type="number" label="Ore straordinario" value={this.state.overtime} onInput={(e) => this.setState({overtime: e.target.value})}></TextField>
                        </FormControl>

                    </Grid>


                    
                </Grid>
                <Button variant="contained" color="primary" onClick={this.addSupplier} style={{marginTop: 32}}>
                        Aggiungi Movimento
                </Button>

                <Snackbar open={this.state.open} autoHideDuration={6000} onClose={() => this.setState({open: false})}>
                    <Alert onClose={() => this.setState({open: false})} severity="success">
                        {this.state.message}
                    </Alert>
                </Snackbar>
            </div>
        )
    }
}

export default withRouter(NuovoMovimento);