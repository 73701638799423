import axios from "axios";
import React from "react";
import { Card, Grid } from "@material-ui/core";
import moment from 'moment';
import Map from './Map';
const Muletto = () => {
    const [forkliftData, setForkliftData] = React.useState({});

    const retrieveData = async()  => {
        const forkliftFetch = await axios.get("https://1mnxyz0inc.execute-api.eu-west-1.amazonaws.com/golive/muletto");
        setForkliftData({...forkliftFetch.data});
    }
    React.useEffect(() => {
        retrieveData();
    }, [])
    return <React.Fragment>
        <h1>Muletto {forkliftData.id}</h1>
        <Grid md={11} style={{height: 400}}>
            <div style={{display: 'block', position: 'relative', width: '95%', height: 400}}>
            {forkliftData.sensors && (
                <Card>
                    <Map locations={{
                        lat: forkliftData.device.lastLatitude,
                        lng: forkliftData.device.lastLongitude
                        }}
                    ></Map>
                </Card>
                
            )}
            </div>        
        </Grid>
        
        {forkliftData.sensors && <Grid container spacing={3} rowSpacing={8} style={{marginTop: 32, width: '95%'}}>
            <Grid xs={12} md={11}>
                <Card style={{paddingLeft: 8, paddingRight: 80}}>
                    <h3>Specifiche tecniche</h3>
                    <ul style={{listStyle: 'none'}}>
                        <li><strong>Nome muletto: </strong> {forkliftData.name}</li>
                        <li><strong>Tipo muletto: </strong> {forkliftData.type}</li>
                        <li><strong>Numero seriale: </strong> {forkliftData.serial}</li>
                        <li><strong>Targa: </strong> {forkliftData.plate}</li>
                        <li><strong>Modello: </strong> {forkliftData.model}</li>

                    </ul>
                </Card>
            </Grid>
            <Grid xs={12} md={6} style={{marginTop: 12}}>
                <Card style={{paddingLeft: 8, paddingRight: 8}}>
                    <h3>Specifiche 4.0</h3>
                    <ul style={{listStyle: 'none'}}>
                        <li><strong>Commessa: </strong> {forkliftData.unit.name}</li>
                        <li><strong>Data di interconnessione: </strong> {moment(forkliftData.interconnectionDate).format('DD/MM/YYYY')}</li>
                        <li><strong>Ultimo rilevamento posizione: </strong> {moment(forkliftData.lastValidPositionDate).format('DD/MM/YYYY HH:mm')}</li>
                        <li><strong>Ultima tramissione: </strong> {moment(forkliftData.lastLogDatetime).format('DD/MM/YYYY HH:mm')}</li><br />
                        <li><strong>IMEI: </strong> {forkliftData.device.imei}</li>
                        <li><strong>SIM ICCID: </strong> {forkliftData.device.iccid}</li><br />
                        <li><strong>Totale tempo in moto: </strong> {(Math.floor(parseInt(forkliftData.total_work_seconds) / 60))} minuti</li>
                        <li><strong>Totale km percorsi: </strong> {forkliftData.total_km} km</li>
                        <li><strong>Ampere consumati: </strong> {forkliftData.consumed_ampere} ah</li>
                        <li><strong>Minuti per giorno in moto (Ultimi 30 giorni): </strong> {forkliftData.statistics.AVG_DASHBOARD_MINUTES_PER_DAY_LAST_30_DAYS.value} minuti</li>
                        <li><strong>Ampere per giorno (Ultimi 30 giorni): </strong> {forkliftData.statistics.AVG_AH_PER_DAY_LAST_30_DAYS.value} ah</li>
                    </ul>
                </Card>
            </Grid>
            <Grid xs={12} md={5} style={{marginLeft: 14, marginTop: 12}}>
                <Card style={{paddingLeft: 8, paddingRight: 8}}>
                    <h3>Sensori</h3>
                    <ul style={{listStyle: 'none'}}>
                        {Object.keys(forkliftData.sensors).map(sensor => (
                            <li>{sensor}: {forkliftData.sensors[sensor].rawValue} - {forkliftData.sensors[sensor].status === "1" ? 'Attivo' : 'Non attivo'}</li>
                        ))}
                    </ul>
                </Card>
            </Grid>
           
        </Grid>}
        
        
    </React.Fragment>
}

export default Muletto;
