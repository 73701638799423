import React, {forwardRef} from 'react';
import Button from '@material-ui/core/Button';
import { withRouter } from 'react-router-dom';
import MaterialTable from '@material-table/core';
import axios from 'axios';
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import Modal from '@mui/material/Modal';
import { Box, TextField, Typography } from '@material-ui/core';

const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
  };

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
  };
  
class Operai extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            workers: [],
            modalOpened: false,
            operaio: {}
        }
    }

    componentDidMount = () => {

        axios.get('https://back.rauccihomedesign.it/api/v1/workers', {
            headers: { Authorization: 'Bearer ' + sessionStorage.getItem('token')}
        }).then((res) => {
            if(res.status === 200){
                this.setState({workers: res.data})
            }
        });

    }
    addSupplier = async () => {
   
        let response = await axios.put(`https://back.rauccihomedesign.it/api/v1/workers/${this.state.operaio.id}`,
            {
                name: this.state.operaio.name,
                price: this.state.operaio.price,
                overtime_price: this.state.operaio.overtime_price
            },
            {
                headers: { Authorization: 'Bearer ' + sessionStorage.getItem('token')}
            }
        );
        
        window.location.reload();
    }
    deleteSupplier = async (id) => {
   
        let response = await axios.delete(`https://back.rauccihomedesign.it/api/v1/workers/${id}`,
            {
                headers: { Authorization: 'Bearer ' + sessionStorage.getItem('token')}
            }
        );
        
        window.location.reload();
    }
    render(){
        return (
            <div>
                <Button variant="contained" color="primary" onClick={() => this.props.history.push('aggiungi-operaio')}>
                    Aggiungi Operaio
                </Button>
                <h1>Operai</h1>
                <MaterialTable title="Elenco Operai" icons={tableIcons} data={this.state.workers} 
                options={{
                    paging:true,
                    pageSize:50,       // make initial page size
                    emptyRowsWhenPaging: false,   // To avoid of having empty rows
                    pageSizeOptions:[50,100,150,200],    // rows selection options,
                  }}
                columns={[
                    {
                        title: 'Nome',
                        field: 'name'
                    },
                    {
                        title: 'Costo',
                        field: 'price'
                    },
                    {
                        title: 'Costo straordinari',
                        field: 'overtime_price'
                    },
                    {
                        title: 'Azioni',
                        field: 'actions',
                        render: data => (<div>
                        <Button variant="contained"
                            onClick={() => this.setState({modalOpened: true, operaio: {...data}})}
                        >
                            Modifica
                        </Button>
                        <Button variant="contained"
                        style={{left: 6}}
                            onClick={() => this.deleteSupplier(data.id)}
                        >
                            Elimina
                        </Button>
                        </div>)
                    }
                ]}></MaterialTable>
                <Modal
                    open={this.state.modalOpened}
                    onClose={() => this.setState({modalOpened: false})}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style}>
                        <Typography id="modal-modal-title" variant="h6" component="h2">
                            Modifica Operaio
                        </Typography>
                        <Box sx={{mt: 2}}>
                            <TextField onChange={(e) => this.setState({operaio: {...this.state.operaio, name: e.target.value}})} label='Nome Operaio' value={this.state.operaio.name}/>
                        </Box>
                        <Box sx={{mt: 1}}>
                            <TextField onChange={(e) => this.setState({operaio: {...this.state.operaio, price: e.target.value}})} label='Costo Operaio' value={this.state.operaio.price}/>
                        </Box>
                        <Box sx={{mt: 1}}>
                            <TextField onChange={(e) => this.setState({operaio: {...this.state.operaio, overtime_price: e.target.value}})} label='Costo Straordinario' value={this.state.operaio.overtime_price}/>
                        </Box>

                        <Button variant="contained" onClick={() => this.addSupplier()}>Salva</Button>
                    </Box>
                </Modal>
            </div>
        )
    }
}

export default withRouter(Operai);