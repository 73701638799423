import React from "react";
import {
  BrowserRouter as RouterV,
  Switch,
  Route,
  Link,
} from "react-router-dom";
import Login from "../pages/Login";
import AddHandle from "../pages/maniglie/AddHandle";
import AddSupplier from "../pages/maniglie/AddSupplier";
import Handle from "../pages/maniglie/Handle";
import Suppliers from "../pages/maniglie/Suppliers";
import Nuovo from "../pages/preventivi/Nuovo";
import Preventivi from "../pages/preventivi/Preventivi";
import AuthenticatedRoute from "./AuthenticatedRoute";
import NuovoFornitore from '../pages/fornitori/Nuovo';
import Fornitori from '../pages/fornitori/Fornitori';
import NuovoContratto from '../pages/contratti/Nuovo';
import Contratti from "../pages/contratti/Contratti";
import Customers from "../pages/customers/Customers";
import Ordinare from "../pages/contratti/Ordinare";
import Ordinato from "../pages/contratti/Ordinato";
import Operai from "../pages/operai/Operai";
import NuovoOperaio from "../pages/operai/Nuovo";
import Movimenti from "../pages/contratti/Movimenti";
import NuovoMovimento from "../pages/contratti/NuovoMovimento";
import NuovoAcconto from "../pages/contratti/AggiungiAcconto";

import DaOrdinare from '../pages/fornitori/DaOrdinare';
import Ordinati from '../pages/fornitori/Ordinati';
import Portafoglio from "../pages/portafoglio/Portafoglio";
import Spesa from "../pages/portafoglio/Spesa";
import Entrata from "../pages/portafoglio/Entrata";
import Trasferisci from "../pages/portafoglio/Trasferisci";
import DaOrdinareList from "../pages/merce/DaOrdinare";


import AggiungiTracciato from '../pages/fornitori/CaricaTracciato';
import InUscita from "../pages/fornitori/inUscita";
import InUscitaContratti from "../pages/contratti/inUscita";

import ChangePassword from "../pages/ChangePassword";
import Dashboard from "../pages/Dashboard";
import Muletto from "../pages/muletto/Muletto";
class Router extends React.Component{
    constructor(props){
      super(props);
    }
    
    getLocation = () => {
      
    }

    render(){
      return (
        
        <RouterV>
            <Switch>
              <Route exact path="/">
                    <AuthenticatedRoute component={Login} />
              </Route>
              
              <Route path="/dashboard">
                    <AuthenticatedRoute child={<Dashboard />} />
              </Route>
              <Route path="/preventivi">
                    <AuthenticatedRoute child={<Preventivi></Preventivi>} />
              </Route>
              <Route path="/clienti">
                    <AuthenticatedRoute child={<Customers></Customers>} />
              </Route>
              <Route path="/nuovo-contratto">
                    <AuthenticatedRoute child={<NuovoContratto></NuovoContratto>} />
              </Route>
              <Route path="/contratti">
                    <AuthenticatedRoute child={<Contratti></Contratti>} />
              </Route>
              <Route path="/nuova-maniglia">
                    <AuthenticatedRoute child={<AddHandle></AddHandle>} />
              </Route>
              <Route path="/maniglie">
                    <AuthenticatedRoute child={<Handle></Handle>} />
              </Route>
              <Route path="/fornitori-maniglie">
                    <AuthenticatedRoute child={<Suppliers></Suppliers>} />
              </Route>
              <Route path="/aggiungi-fornitore-maniglie">
                    <AuthenticatedRoute child={<AddSupplier></AddSupplier>} />
              </Route>
              <Route path="/nuovo-preventivo">
                    <AuthenticatedRoute child={<Nuovo></Nuovo>} />
              </Route>
              <Route path="/fornitori">
                  <AuthenticatedRoute child={<Fornitori></Fornitori>} />
              </Route>
              <Route path="/aggiungi-fornitore">
                  <AuthenticatedRoute child={<NuovoFornitore></NuovoFornitore>} />
              </Route>
              
              <Route path="/operai">
                  <AuthenticatedRoute child={<Operai></Operai>} />
              </Route>
              <Route path="/aggiungi-operaio">
                  <AuthenticatedRoute child={<NuovoOperaio></NuovoOperaio>} />
              </Route>

              <Route path="/da-ordinare">
                  <AuthenticatedRoute child={<Ordinare></Ordinare>} />
              </Route>
              <Route path="/ordinato">
                  <AuthenticatedRoute child={<Ordinato></Ordinato>} />
              </Route>

              <Route path="/movimenti">
                  <AuthenticatedRoute child={<Movimenti></Movimenti>} />
              </Route>
              <Route path="/aggiungi-movimento">
                  <AuthenticatedRoute child={<NuovoMovimento></NuovoMovimento>} />
              </Route>
              <Route path="/aggiungi-acconto">
                  <AuthenticatedRoute child={<NuovoAcconto></NuovoAcconto>} />
              </Route>
              <Route path="/fornitori-da-ordinare">
                  <AuthenticatedRoute child={<DaOrdinare></DaOrdinare>} />
              </Route>
              <Route path="/fornitori-ordinati">
                  <AuthenticatedRoute child={<Ordinati></Ordinati>} />
              </Route>
              <Route path="/portafoglio">
                  <AuthenticatedRoute child={<Portafoglio></Portafoglio>} />
              </Route>
              <Route path="/aggiungi-spesa">
                  <AuthenticatedRoute child={<Spesa></Spesa>} />
              </Route>
              <Route path="/aggiungi-entrata">
                  <AuthenticatedRoute child={<Entrata></Entrata>} />
              </Route>

              <Route path="/trasferisci">
                  <AuthenticatedRoute child={<Trasferisci></Trasferisci>} />
              </Route>

              <Route path="/carica-tracciato">
                  <AuthenticatedRoute child={<AggiungiTracciato></AggiungiTracciato>} />
              </Route>

              <Route path="/da-ordinare-lista">
                  <AuthenticatedRoute child={<DaOrdinareList></DaOrdinareList>} />
              </Route>
              <Route path="/accedi">
                    <Login />
              </Route>
              <Route path="/change_password">
                  <ChangePassword></ChangePassword>
              </Route>
              <Route path="/in-uscita">
                  <AuthenticatedRoute child={<InUscita></InUscita>} />
              </Route>

              <Route path="/in-uscita-contratto">
                  <AuthenticatedRoute child={<InUscitaContratti></InUscitaContratti>} />
              </Route>
              <Route path="/muletto">
                  <AuthenticatedRoute child={<Muletto></Muletto>} />
              </Route>
            </Switch>
          </RouterV>
        );
    }
   
}

export default Router;