import React, {forwardRef} from 'react';
import Button from '@material-ui/core/Button';
import { withRouter } from 'react-router-dom';
import MaterialTable from '@material-table/core';
import axios from 'axios';
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';

import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MenuButton from '../components/MenuButton';

const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
  };


class Fornitori extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            suppliers: [],
            menuOpen: null
        }
    }

    componentDidMount = () => {

        axios.get('https://back.rauccihomedesign.it/api/v1/suppliers', {
            headers: { Authorization: 'Bearer ' + sessionStorage.getItem('token')}
        }).then((res) => {
            if(res.status === 200){
                this.setState({suppliers: res.data})
            }
        });

    }

    render(){
        return (
            <div>
                <Button variant="contained" color="primary" onClick={() => this.props.history.push('aggiungi-fornitore')}>
                    Aggiungi Fornitori
                </Button>
                <h1>Fornitori</h1>
                <MaterialTable title="Elenco Fornitori" icons={tableIcons} data={this.state.suppliers} options={{
                        paging:true,
                        pageSize:50,       // make initial page size
                        emptyRowsWhenPaging: false,   // To avoid of having empty rows
                        pageSizeOptions:[50,100,150,200],    // rows selection options
                      }}
                    columns={[
                    {
                        title: 'Etichetta',
                        field: 'name'
                    },
                    {
                        title: 'Ragione Sociale',
                        field: 'company_name'
                    },
                    {
                        title: 'Partita IVA',
                        field: 'vat_number'
                    },
                    {
                        title: 'Azioni',
                        field: 'name',
                        render: dataPass => {return (
                        <div>
                            <MenuButton items={[{title: 'Da ordinare', href: "/fornitori-da-ordinare"}, {title: 'Ordinata', href: "/fornitori-ordinati"}, {title: 'In Uscita', href: "/in-uscita"}, {title: 'Carica Tracciato', href: "/carica-tracciato"}]} data={dataPass.id}></MenuButton>
                        </div>
                        )}
                    }
                ]}></MaterialTable>
            </div>
        )
    }
}

export default withRouter(Fornitori);